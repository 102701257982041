import { banks } from '../constants'

export const formatMaritalState = status => {
  switch (status) {
    case 'C':
      return 'Casado'
    case 'D':
      return 'Divorciado'
    case 'S':
      return 'Solteiro'
    case 'V':
      return 'Viúvo(a)'
    default:
      return 'Indefinido'
  }
}

export const formatGender = status => {
  switch (status) {
    case 'M':
      return 'Masculino'
    case 'F':
      return 'Feminino'
    default:
      return 'Indefinido'
  }
}

export const getAccountType = accountType => {
  switch (accountType) {
    case 'CC':
      return 'Conta Corrente'
    case 'CP':
      return 'Conta Poupança'
    default:
      return 'Indefinido'
  }
}

export const formatDocumentType = type => {
  switch (type) {
    case 'C':
      return 'CNH'
    case 'CC':
      return 'Certidão de nascimento'
    case 'CP':
      return 'CPF'
    case 'CR':
      return 'Comprovante de residência'
    case 'CT':
      return 'Carteira de trabalho'
    case 'IR':
      return 'Imposto de renda'
    case 'O':
      return 'OAB'
    case 'P':
      return 'Passaporte'
    case 'R':
      return 'RG'
    case 'RV':
      return 'RG Verso'
    case 'RN':
      return 'RNE'
    default:
      return 'Tipo não identificado'
  }
}

export const getBankName = code => Object.keys(banks).filter(bank => bank.split(' ')[0] === code)

export const formatCpf = cpf => cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')

export const formatMoneyToCsv = value => `${value.split('.')[0]}k`

const formatToBrl = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
})

export const formatValueToBrl = value => {
  return isNaN(Number(value)) ? formatToBrl.format(0) : formatToBrl.format(Number(value))
}

export const formatPercentageToBack = value =>
  Number(
    String(value)
      .replace(',', '.')
      .replace(/\D*$/, '')
  )

export const formatCurrencyToBack = value => {
  if (value) {
    return Number(
      value
        .replace(/^\D*/, '')
        .replace(/\./g, '')
        .replace(',', '.')
    )
  }
  return 0
}

export const formatCNPJ = cnpj => {
  const cnpjPattern = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/
  return cnpj.replace(/\D/g, '').replace(cnpjPattern, '$1.$2.$3/$4-$5')
}

export const formatPhoneWithoutPrefix = number => {
  const phonePattern = /(\d{4}\d?)(\d{4})/
  return number ? number.replace(phonePattern, '$1-$2') : '-'
}

export const abbreviateFullName = (fullName, maxLength) => {
  try {
    if (!fullName) throw new Error('Nome completo inválido')
    const names = fullName.split(' ').filter(name => name)
    if (names.length === 0) throw new Error('Nome completo inválido')
    const firstName = names[0]
    const lastName = names[names.length - 1]
    if (firstName === lastName) return firstName
    return `${firstName} ${lastName}`.length > maxLength ? `${firstName} ${lastName[0]}.` : `${firstName} ${lastName}`
  } catch (error) {
    console.log(error)
    return ''
  }
}

export const abbreviateFullNameResp = (fullName, maxLength) => {
  try {
    if (!fullName) throw new Error('Nome completo inválido')
    const names = fullName.split(' ').filter(name => name)
    if (names.length === 0) throw new Error('Nome completo inválido')
    const firstName = names[0]
    const lastName = names[names.length - 1]
    if (firstName === lastName) return firstName
    return `${firstName}`.length > maxLength ? `${firstName}.` : `${firstName}`
  } catch (error) {
    console.log(error)
    return ''
  }
}

export const formatInputPhone = value =>
  value.length <= 10
    ? `(${value.slice(0, 2)}) ${value.slice(2, 6)}-${value.slice(6, 10)}`
    : `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7, 11)}`

export const formatName = name => {
  if (typeof name !== 'string') return ''
  return name
    .toLowerCase()
    .split(/\s/)
    .reduce((acc, currentName) => {
      if (!currentName) return acc
      const captilizedName = currentName.charAt(0).toUpperCase() + currentName.slice(1)
      return `${acc} ${captilizedName}`.trim()
    }, '')
}

export const formatNameFirstLetterUpperCase = name => {
  if (!!name) {
    return name.toLowerCase().replace(/(?:^|\s)(?!da|de|do|das|dos)\S/g, l => l.toUpperCase())
  }
  return ''
}

export const formatZipCode = string => {
  const regex = /(\d{5}\d?)(\d{3})/
  return string.replace(regex, '$1-$2')
}

export const formatCurrency = value => {
  return new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL'
  }).format(value)
}

export const formatCurrencyRound = (sumValue, value) => {
  return new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL'
  }).format(
    Number(
      formatCurrency(sumValue + value)
        .slice(3)
        .replaceAll(',', '.')
    ) - sumValue
  )
}

export const formatTax = number => String(number).replace('.', ',') + '%'

export const NumberTax = number =>
  Number(
    String(number)
      .replace(',', '.')
      .replace('%', '')
  )

export const testFilter = obj => {
  const value = Object.entries(obj)
    .map(item => {
      if (item[0] !== 'all') {
        return item[1] ? 1 : 0
      } else {
        return 0
      }
    })
    .reduce((previousValue, currentValue) => previousValue + currentValue)
  return value > 1 ? true : false
}

export const expectedDate = item => (!!item.expire_date_delayed ? item.expire_date_delayed : item.expire_date)
