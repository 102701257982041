import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { toast } from 'react-toastify'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { Row, Button, Radio } from '../../../../components/v2Components'
import { Option, TableBase, Drawer } from '../'
import { Card } from './component'

import { DrawerTitle, Value, ViewInvestment, Form, OptionClose, DivOptions } from './style'

import { IconLaunch, IconCalendar } from '../../../../components/v2Components/icons'

import { formatCurrency } from '../../../../helpers/formatters'
import { exportExtract } from '../../../../services/v3'

const Extract = ({ investor, allExtract, props }) => {
  const [extract, setExtract] = useState([])
  const [filteredExtract, setFilteredExtract] = useState([])

  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const [orderBy, setOrderBy] = useState('all')

  const [rowLength, setRowLength] = useState(8)
  const [pageSelected, setPageSelected] = useState(0)

  const [selectedDateStart, setSelectedDateStart] = useState(new Date())
  const [selectedDateEnd, setSelectedDateEnd] = useState(new Date())

  const [filterPeriod, setFilterPeriod] = useState('')

  const { handleSubmit, control } = useForm()

  const ExtractColumns = useMemo(
    () => [
      {
        Header: 'Data',
        accessor: 'date'
      },
      {
        Header: 'Descrição',
        accessor: 'description'
      },
      {
        Header: 'Valor (R$)',
        accessor: 'value'
      },
      {
        Header: 'Amortização',
        accessor: 'amortization'
      },
      {
        Header: 'Juros',
        accessor: 'tax'
      },
      {
        Header: 'Desconto IR',
        accessor: 'ir'
      },
      {
        Header: 'Parcela',
        accessor: 'parcel'
      },
      {
        Header: '',
        accessor: 'detail'
      }
    ],
    []
  )

  const orderByRecent = useCallback((a, b) => {
    const date1 = new Date(a.date.replace(/\//g, '-').replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3')).valueOf()
    const date2 = new Date(b.date.replace(/\//g, '-').replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3')).valueOf()

    if (date1 > date2) {
      return -1
    }

    if (date1 < date2) {
      return 1
    }

    return 0
  }, [])

  const filterByDays = useCallback((a, sel, days) => {
    const date1 = new Date(a.date.replace(/\//g, '-').replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3')).valueOf()
    const date2 = new Date()

    if (sel === 'last') {
      date2.setDate(date2.getDate() - days)
      if (date1 - date2 >= 0) {
        return true
      }
    }

    if (sel === 'next') {
      date2.setDate(date2.getDate() + days)
      if (date1 - date2 <= 0) {
        return true
      }
    }
  }, [])

  const filterByPeriod = useCallback(
    a => {
      let data = new Date(a.date.replace(/\//g, '-').replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3')).valueOf()
      let dataStart = new Date(selectedDateStart).setUTCHours(0)
      let dataEnd = new Date(selectedDateEnd).setUTCHours(0)

      if (dataStart <= data && data <= dataEnd) {
        return true
      }
    },
    [selectedDateStart, selectedDateEnd]
  )

  const handleExport = async () => {
    try {
      let filter = ''
      switch (orderBy) {
        case 'last7':
          filter = 'period=7&export_as_csv=true'

          break
        case 'last15':
          filter = 'period=15&export_as_csv=true'

          break
        case 'last30':
          filter = 'period=30&export_as_csv=true'

          break
        case 'all':
          filter = 'export_as_csv=true'

          break
        case 'period':
          filter = `begin=${selectedDateStart.toLocaleDateString('en-CA')}&end=${selectedDateEnd.toLocaleDateString(
            'en-CA'
          )}&export_as_csv=true`

          break
        default:
          break
      }

      const { data: response } = await exportExtract(investor.id, filter)
      const element = document.createElement('a')
      element.href = URL.createObjectURL(
        new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
      )
      element.download = 'Lançamentos.xlsx'
      element.click()
    } catch (error) {
      console.log(error)
      toast.error('Ocorreu um erro ao exportar a listagem em CSV')
    }
  }

  const handleResetDataPicker = useCallback(() => {
    setSelectedDateStart(new Date())
    setSelectedDateEnd(new Date())
  }, [])

  const handleOrderExtract = useCallback(
    (orderExtract = 'all') => {
      try {
        let order = []

        switch (orderExtract) {
          case 'last7':
            order = extract.filter(item => filterByDays(item, 'last', 7))

            break
          case 'last15':
            order = extract.filter(item => filterByDays(item, 'last', 15))

            break
          case 'last30':
            order = extract.filter(item => filterByDays(item, 'last', 30))

            break
          case 'all':
            order = extract

            break
          case 'period':
            order = extract.filter(filterByPeriod)

            break
          default:
            break
        }

        order.sort(orderByRecent)

        const response = order.map(item => item)

        setFilteredExtract(response)
      } catch (err) {
        console.log(err)
      }
    },
    [extract, filterByDays, filterByPeriod, orderByRecent]
  )

  const handleFilter = useCallback(
    (data, orderBy, orderByFuture) => {
      try {
        setPageSelected(0)
        setRowLength(Number(data.pagination))
        setOrderBy(!!data.order ? data.order : orderBy)
        handleOrderExtract(data.order)
      } catch (err) {
        toast.error('Não foi possível realizar esse filtro.')
      } finally {
        setIsOpenFilter(false)
      }
    },
    [handleOrderExtract]
  )

  const handleFilterCleaning = useCallback(() => {
    setOrderBy('all')
    handleOrderExtract('all')
    setRowLength(8)
  }, [handleOrderExtract])

  useEffect(() => {
    const fetchInfos = async () => {
      try {
        if (allExtract.entries.length === 0) {
          throw Error('1')
        }

        const extractResponse = allExtract.entries.map(item => {
          return {
            date: item.date,
            description: item.description,
            value: <Value title={formatCurrency(item.amount)}>{formatCurrency(item.amount)}</Value>,
            ir: /[-]/.test(formatCurrency(item.amount)) ? (
              '-'
            ) : (
              <Value title={formatCurrency(item.IR_based_on_past_days)}>
                {formatCurrency(item.IR_based_on_past_days)}
              </Value>
            ),
            amortization: /[-]/.test(formatCurrency(item.amount)) ? '-' : formatCurrency(item.amortization),
            tax: /[-]/.test(formatCurrency(item.amount)) ? '-' : formatCurrency(item.tax_per_installment),
            parcel: !!item.total_installments ? `${item.installment_number}/${item.total_installments}` : '-',
            detail: (
              <Link
                to={`/usuarios/${props.match.params.id}/detalhe-investimento/${item.campaign_id}`}
                title='Ver investimento'
              >
                <Row title='Ver investimento' justifyContent='flex-end' color='#076F9F'>
                  <IconLaunch />
                  <ViewInvestment>Ver investimento</ViewInvestment>
                </Row>
              </Link>
            )
          }
        })

        setExtract(extractResponse)
      } catch (error) {
        console.log(error)
        if (!!error.message && error.message === '1') {
          toast.info('Você ainda não tem nenhum Lançamento')
        }
      }
    }
    fetchInfos()
  }, [allExtract])

  useEffect(() => {
    handleOrderExtract('all')
  }, [extract, handleOrderExtract])

  return (
    <div>
      <DivOptions>
        <Option name='Exportar' imgUrl='/assets/images/export.svg' title='Exportar' onPress={() => handleExport()} />
        <Option
          name='Filtrar'
          imgUrl='/assets/images/filter.svg'
          title='Filtrar'
          onPress={() => setIsOpenFilter(true)}
        />
        {(orderBy !== 'all' || rowLength !== 8) && (
          <OptionClose
            name='Limpar Filtro'
            imgUrl='/assets/images/closeFilter.svg'
            onPress={() => handleFilterCleaning(0)}
          />
        )}
      </DivOptions>

      <>
        <TableBase
          hasPaginationTop
          hasPaginationBottom
          widthHidden={730}
          columns={ExtractColumns}
          data={filteredExtract}
          length={rowLength}
        />

        <Card
          releases={filteredExtract}
          hasPaginationTop
          hasPaginationBottom
          offset={pageSelected}
          rowCount={rowLength}
          navigationTo={setPageSelected}
        />
      </>

      <Drawer
        anchor='right'
        open={isOpenFilter}
        onClose={() => setIsOpenFilter(false)}
        iconClose
        titleContainer={<DrawerTitle>Filtrar</DrawerTitle>}
      >
        <Form onSubmit={handleSubmit(data => handleFilter(data, orderBy))}>
          <main>
            <section>
              <h2>Período:</h2>

              <Radio
                control={control}
                name='order'
                defaultValue={String(orderBy)}
                onChange={item => setFilterPeriod(item.target.defaultValue)}
                options={[
                  {
                    value: 'last7',
                    label: 'Últimos 7 dias'
                  },
                  {
                    value: 'last15',
                    label: 'Últimos 15 dias'
                  },
                  {
                    value: 'last30',
                    label: 'Últimos 30 dias'
                  },
                  {
                    value: 'all',
                    label: 'Todos'
                  },
                  {
                    value: 'period',
                    label: 'Período específico'
                  }
                ]}
              />

              {(filterPeriod === 'period' || filterPeriod === 'periodFuture') && (
                <Row justifyContent='space-between' style={{ gap: '24px' }}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    format='dd/MM/yyyy'
                    margin='normal'
                    id='date-picker-inline'
                    label='DE'
                    value={selectedDateStart}
                    inputVariant='outlined'
                    onChange={data => setSelectedDateStart(data)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    keyboardIcon={<IconCalendar />}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    format='dd/MM/yyyy'
                    margin='normal'
                    id='date-picker-inline'
                    label='ATÉ'
                    value={selectedDateEnd}
                    inputVariant='outlined'
                    onChange={data => setSelectedDateEnd(data)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    keyboardIcon={<IconCalendar />}
                  />
                </Row>
              )}
            </section>

            <section>
              <h2>Itens por página:</h2>

              <Radio
                control={control}
                name='pagination'
                defaultValue={String(rowLength)}
                options={[
                  {
                    value: '8',
                    label: '8 itens'
                  },
                  {
                    value: '24',
                    label: '24 itens'
                  },
                  {
                    value: '40',
                    label: '40 itens'
                  },
                  {
                    value: '10000000',
                    label: 'Todos'
                  }
                ]}
              />
            </section>
          </main>

          <footer>
            {filterPeriod !== 'period' && <Button type='submit'>Filtrar</Button>}
            {filterPeriod === 'period' && (
              <Row width='100%' style={{ gap: '24px' }}>
                <Button variant='outlined' onClick={handleResetDataPicker}>
                  Limpar filtros
                </Button>
                <Button type='submit'>Filtrar</Button>
              </Row>
            )}
          </footer>
        </Form>
      </Drawer>
    </div>
  )
}

export default Extract
